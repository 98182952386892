import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'teaching-philosophy',
  templateUrl: './philosophy.component.html',
  styleUrls: ['./philosophy.component.css']
})
export class PhilosophyComponent implements OnInit  {
  public ngOnInit(){
      
  }
}