import { Component, OnInit } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';

@Component({
  selector: 'cellostudio',
  templateUrl: './cellostudio.component.html',
  styleUrls: ['./cellostudio.component.css'],
  animations:[
    trigger('highlightChanged',
    [
      state('show', style({opacity: 1})),
      state('hide', style({opacity: 0})),
      transition('* => show', [ animate('.5s ease-in') ]),
      transition('show => hide', [ animate('.5s ease-out') ])
    ]
    )
  ]
})
export class CelloStudioComponent implements OnInit  {
  currentHighlight: string;
  changed:boolean;
  highlights:Array<string> = [
    "Congratulations: Joey B Book 5/6 Recital",
    "Congratulations: Jackson M Book 5 Recital",
    "Congratulations: Max S Book 4 Recital",
    "Congratulations: Dalloway S chosen as performer for ISSI Honors recital",
    "Amichevoli performs at ISSI Honors Recital on June 20 at 1 pm",
    "Aliya G was in Copland Orchestra at 1st Suzuki Convention of the Americas in Cancun Mexico",
    "Congratulations: Lucy J Senior Recital",    
    "Congratulations: Ellie W Book 10 Recital",
    "Congratulations: William M Book 5/6 Recital",
    "Amichevoli performed at Aspen Ridge West Rehabilitation Center",
    "Congratulations: Stella W and Nathan B performed at Salute to Youth",
    "Congratulations: Breighton P won honorable mention at Youth Guild Recital Audition",
    "Congratulations: Yvonne K accepted to St. Paul Chamber Music Camp",    
    "Congratulations: Drew S Book 10 Recital",
    "Congratulations: Yvonne K winner at ASTA"
  ]
  public ngOnInit(){
      this.changed = true;
      this.currentHighlight = this.highlights[0];
      this.slideshow();
  }

  slideshow(){
    for(let i = 0; i < this.highlights.length; i++){
      let hl = this.highlights[i];
      setTimeout(() => {
        this.currentHighlight = hl;
        this.changed = !this.changed;
        if (i == this.highlights.length -1){
          this.slideshow();
        }
      }, 2500*(i+1));
    }
  }
}