import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tuning-tones',
  templateUrl: './tuning-tones.component.html',
  styleUrls: ['./tuning-tones.component.css']
})
export class TuningTonesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
