import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-warm-ups',
  templateUrl: './warm-ups.component.html',
  styleUrls: ['./warm-ups.component.css']
})
export class WarmUpsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
