import { Component, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';

@HostListener('window:resize', ['$event'])

@Component({
  selector: 'gcs-app',
  templateUrl : 'gcs-app.component.html' 
})

export class GcsAppComponent implements OnInit {
  title = 'app';
  currentUrl:string;
  private fragment: string;
  @ViewChild('pageLayout') elementView: ElementRef;


  constructor(private _router:Router, private route:ActivatedRoute){
    _router.events.subscribe(event => {

      if (event instanceof NavigationEnd ) {
        this.setPageColor(_router.url);
      }
    });
  }
  ngOnInit() {
    this.resizeViewHeight(window.innerHeight);
  }
  
  onResize(event) {
    console.log('resizing');
    this.resizeViewHeight(event.target.innerHeight);
  }

  resizeViewHeight(height:number){
    let vh = height *.01;
    console.log(vh);
    document.documentElement.style.setProperty('--vh', `${vh}px`)
  }

  setPageColor(pageUrl:string){
    switch(pageUrl){
      case "/parenteducation":
        document.documentElement.style.setProperty('--bgcolor', 'rgb(176, 236, 184)');
        break;
      case "/bio":
          document.documentElement.style.setProperty('--bgcolor', 'rgb(253, 212, 253)');
          break;
      default:
        document.documentElement.style.setProperty('--bgcolor', `rgb(191, 200, 240)`);
    }
  }
}