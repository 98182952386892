import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'parents',
  templateUrl: './parents.component.html',
  styleUrls: ['./parents.component.css']
})
export class ParentsComponent implements OnInit {
  public ngOnInit(){
      
  }
}