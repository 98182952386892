import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router'
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import { AlertModule } from 'ngx-bootstrap';
import { HomeComponent } from './home/home.component';
import { IntroComponent } from './intro/intro.component';
import { HeaderComponent } from './header/header.component';
import { PhilosophyComponent } from './philosophy/philosophy.component';
import { CelloStudioComponent } from './cellostudio/cellostudio.component';
import { ParentsComponent } from './parents/parents.component';
import { BioComponent } from './bio/bio.component';
import { CalendarComponent } from './calendar/calendar.component';
import { GcsAppComponent } from './gcs-app.component';
import { NavbarComponent } from './navbar/navbar.component';
import { LogoComponent } from './logo/logo.component';
import { ScheduleComponent } from './schedule/schedule.component';
import { LoginComponent } from './login/login.component';
import { CookieService } from 'ngx-cookie-service';
import { LocationComponent } from './location/location.component';
import { appRoutes } from './routes';
import { MembershipStatusComponent } from './membership-status/membership-status.component';
import { TuitionComponent } from './tuition/tuition.component';
import { FaqComponent } from './faq/faq.component';
import { PoliciesComponent } from './policies/policies.component';
import { WarmUpsComponent } from './warm-ups/warm-ups.component';
import { PracticeIdeasComponent } from './practice-ideas/practice-ideas.component';
import { ScalesComponent } from './scales/scales.component';
import { TuningTonesComponent } from './tuning-tones/tuning-tones.component';
import { GroupClassPiecesComponent } from './group-class-pieces/group-class-pieces.component';
import { ContactComponent } from './contact/contact.component';
import { ResourcesComponent } from './resources/resources.component';
import { RainbowTangoTwinkleComponent } from './rainbow-tango-twinkle/rainbow-tango-twinkle.component';
import { ZoomInfoComponent } from './zoom-info/zoom-info.component';


@NgModule({
  declarations: [
    GcsAppComponent,
    HomeComponent,
    IntroComponent,
    HeaderComponent,
    PhilosophyComponent,
    CelloStudioComponent,
    ParentsComponent,
    BioComponent,
    CalendarComponent,
    NavbarComponent,
    LogoComponent,
    ScheduleComponent,
    LoginComponent,
    LocationComponent,
    MembershipStatusComponent,
    TuitionComponent,
    FaqComponent,
    PoliciesComponent,
    WarmUpsComponent,
    PracticeIdeasComponent,
    ScalesComponent,
    TuningTonesComponent,
    GroupClassPiecesComponent,
    ContactComponent,
    ResourcesComponent,
    RainbowTangoTwinkleComponent,
    ZoomInfoComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AlertModule,
    FormsModule,
    HttpClientModule,
    RouterModule.forRoot(
      appRoutes, {scrollPositionRestoration: 'enabled'}
    )
  ],
  providers: [
    CookieService,
    {provide: LocationStrategy, useClass: HashLocationStrategy}
  ],
  bootstrap: [GcsAppComponent]
})
export class AppModule { }
