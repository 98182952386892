import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'calendar',
  templateUrl: './calendar.component.html',
  styleUrls: [
    'calendar.component.css'
  ]
})
export class CalendarComponent {

  public shareableLink = "https://calendar.google.com/calendar/u/0/embed?src=k24f4g0f17j4ubo9qdkho7e6v8@group.calendar.google.com&ctz=America/Denver"

  constructor(public http:HttpClient){}
  ngOnInit(): void {
    // this.http.get(this.iframeSrc).subscribe(result=>{

    // },error=>{
    //   window.location.href = this.iframeSrc;
    // })
  }
}