import { Routes } from '@angular/router'

import { 
    CalendarComponent
 } from './calendar/calendar.component'
import { HomeComponent } from './home/home.component';
import { ScheduleComponent } from './schedule/schedule.component';
import { LoginComponent } from './login/login.component';
import { LocationComponent } from './location/location.component';
import { CelloStudioComponent } from './cellostudio/cellostudio.component';
import { ParentsComponent } from './parents/parents.component';
import { BioComponent } from './bio/bio.component';
import { MembershipStatusComponent } from './membership-status/membership-status.component';
import { TuitionComponent } from './tuition/tuition.component';
import { FaqComponent } from './faq/faq.component';
import { PoliciesComponent } from './policies/policies.component';
import { WarmUpsComponent } from './warm-ups/warm-ups.component';
import { PracticeIdeasComponent } from './practice-ideas/practice-ideas.component';
import { ScalesComponent } from './scales/scales.component';
import { TuningTonesComponent } from './tuning-tones/tuning-tones.component';
import { GroupClassPiecesComponent } from './group-class-pieces/group-class-pieces.component';
import { ContactComponent } from './contact/contact.component';
import { ResourcesComponent } from './resources/resources.component';
import { RainbowTangoTwinkleComponent } from './rainbow-tango-twinkle/rainbow-tango-twinkle.component';
import { ZoomInfoComponent } from './zoom-info/zoom-info.component';



export const appRoutes:Routes = [
    { path: 'contact', component: ContactComponent},
    { path: 'resources', component: ResourcesComponent},
    { path: 'tuning-tones', component: TuningTonesComponent},
    { path: 'group-class-pieces', component: GroupClassPiecesComponent},
    { path: 'scales', component: ScalesComponent},
    { path: 'practice-ideas', component: PracticeIdeasComponent},
    { path: 'warm-ups', component: WarmUpsComponent},
    { path: 'faq', component: FaqComponent},
    { path: 'policies', component: PoliciesComponent},
    { path: 'membership-status', component: MembershipStatusComponent},
    { path: 'tuition', component: TuitionComponent},
    { path: 'calendar', component: CalendarComponent},
    { path: 'schedule', component: ScheduleComponent},
    { path: 'login', component: LoginComponent},
    { path: 'location', component: LocationComponent},
    { path: 'studio', component: CelloStudioComponent },
    { path: 'parenteducation', component: ParentsComponent },
    { path: 'bio', component: BioComponent },
    { path: 'rainbow-tango-twinkle', component: RainbowTangoTwinkleComponent },
    { path: 'zoom-info', component: ZoomInfoComponent },

    { path: '', component: HomeComponent }
   ]