import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../authentication.service'

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  password:string;

  constructor(private authService:AuthenticationService) { }
  login(){
    this.authService.authenticate(this.password);
  }
  ngOnInit() {
  }

}
