import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgAnimateScrollService } from 'ng-animate-scroll';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styles: ['.logo{cursor:pointer;}']
})
export class LogoComponent implements OnInit {

  url:string;
  constructor(public router:Router, public animateScrollService:NgAnimateScrollService) { }

  ngOnInit() {
    this.url = this.router.url;
  }
  goHome(){
    console.log("got here");
    if (this.router.url == "/"){
      //this.animateScrollService.scrollToElement("home", 750);
      window.scroll({
        top: 0, 
        left: 0, 
        behavior: 'smooth'
      });
    }
    else{
      this.router.navigate(['/']);
    }
  }


}
