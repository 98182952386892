import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service'

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  pass:string = "Cellomazing";
  isLoggedIn() : boolean{
    return this.cookieService.get('gardnercellostudio') == 'loggedin';
  }

  constructor(private cookieService:CookieService) { }

  

  authenticate(password:string){
    if (password == this.pass){
      this.cookieService.set('gardnercellostudio', 'loggedin', 1, null, null, true, "Strict");
      console.log("COOKIE: ", this.cookieService.get("gardnercellostudio"));
    }
  }
}
