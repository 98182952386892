import { Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { NgAnimateScrollService } from 'ng-animate-scroll';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent {
  wasInside:boolean;
  constructor(public animateScrollService:NgAnimateScrollService, public router: Router){

  }

  @HostListener('click')
  clickInside(){
    this.wasInside = true;
  }

  @HostListener('document:click', ['$event'])
  clickout(event){
    if (!this.wasInside){
      this.navbarOpen = false;
    }
    this.wasInside = false;
  }
  navbarOpen:boolean = false;

  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;

  }

  goTo(location:string){
    if (this.router.url == "/"){
      this.animateScrollService.scrollToElement(location,750);
    }
    else{
      this.router.navigate(['/']).then(()=>{
        this.animateScrollService.scrollToElement(location,750);
      });;
    }
  }

}
