import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'bio',
  templateUrl: './bio.component.html',
  styleUrls: ['./bio.component.css']
})
export class BioComponent implements OnInit {
  olderChildAge:number;
  youngerChildAge:number;
  public ngOnInit(){
    let olderBirthday = new Date("April 12 2007");
    let youngerBirthday = new Date("October 1 2008");
    let timeDiff = Math.abs(Date.now() - olderBirthday.getTime());
    this.olderChildAge = Math.floor((timeDiff / (1000 * 3600 * 24))/365.25);
    timeDiff = Math.abs(Date.now() - youngerBirthday.getTime());
    this.youngerChildAge = Math.floor((timeDiff / (1000 * 3600 * 24))/365.25);
  }
}