import { Component, OnInit, HostListener } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  host: {'(window:scroll)' : 'onWindowScroll($event)'},
})

export class HeaderComponent implements OnInit {
  title = 'Gardner Cello Studio';
  shouldBeOpaque7:boolean = false;
  shouldBeOpaque5:boolean = false;
  shouldBeFixed:boolean = false;
  public ngOnInit(){
      
  }
  public onWindowScroll(event: Event): void {
    this.shouldBeOpaque7 = window.pageYOffset > 0;
    this.shouldBeFixed = window.pageYOffset > 0;
    this.shouldBeOpaque5 = window.pageYOffset > 700;
  }
}