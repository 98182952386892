import { Component, OnInit } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthenticationService } from '../authentication.service'

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.css']
})
export class ScheduleComponent implements OnInit {
  public isLoggedIn = false;
  constructor(public authService:AuthenticationService, private router: Router) { }

  ngOnInit() {
    //this.isLoggedIn = this.authService.isLoggedIn();
    if (!this.authService.isLoggedIn()){
      //this.router.navigate(['/login']);
    }
  }

}
