import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ranbow-tango-twinkle',
  templateUrl: './rainbow-tango-twinkle.component.html',
  styleUrls: ['./rainbow-tango-twinkle.component.css']
})
export class RainbowTangoTwinkleComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
