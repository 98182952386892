import { Component } from '@angular/core';
import { NgAnimateScrollService } from 'ng-animate-scroll';


@Component({
  selector: 'home',
  templateUrl : './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent {
  constructor(public animateScrollService:NgAnimateScrollService){

  }
  scroll(location:string) {
    //el.scrollIntoView();
    this.animateScrollService.scrollToElement(location, 750);
  }
}